export default {
  LOAD_ORDER_SUCCEEDED: "LOAD_ORDER_SUCCEEDED",
  LOAD_ORDER_FAILED: "LOAD_ORDER_FAILED",

  GET_ORDER_REQUEST: "GET_ORDER_REQUEST",
  GET_ORDER_SUCCESS: "GET_ORDER_SUCCESS",
  GET_ORDER_FAILED: "GET_ORDER_FAILED",
  POST_ORDER_REQUEST: "POST_ORDER_REQUEST",
  POST_ORDER_SUCCESS: "POST_ORDER_SUCCESS",
  POST_ORDER_FAILED: "POST_ORDER_FAILED",
  UPDATE_ORDER_REQUEST: "UPDATE_ORDER_REQUEST",
  UPDATE_ORDER_SUCCESS: "UPDATE_ORDER_SUCCESS",
  UPDATE_ORDER_FAILED: "UPDATE_ORDER_FAILED",
  PAY_ORDER_REQUEST: "PAY_ORDER_REQUEST",
  PAY_ORDER_SUCCESS: "PAY_ORDER_SUCCESS",

  GET_USERS_REQUESTED: "GET_USERS_REQUESTED",
  GET_USERS_SUCCESS: "GET_USERS_SUCCESS",
  GET_USERS_FAILED: "GET_USERS_FAILED",
  GET_EMPLOYEES_REQUESTED: 'GET_EMPLOYEES_REQUESTED',
  GET_EMPLOYEES_SUCCESS: 'GET_EMPLOYEES_SUCCESS',
  GET_EMPLOYEES_FAILED: 'GET_EMPLOYEES_FAILED',

  GET_HISTORY_REQUEST: "GET_HISTORY_REQUEST",
  GET_HISTORY_SUCCESS: "GET_HISTORY_SUCCESS",
  GET_HISTORY_FAILED: "GET_HISTORY_FAILED",
  UPDATE_PROFILE_REQUEST: "UPDATE_PROFILE_REQUEST",
  UPDATE_PROFILE_SUCCESS: "UPDATE_PROFILE_SUCCESS",
  UPDATE_PROFILE_FAILED: "UPDATE_PROFILE_FAILED",
  UPDATE_ASSIGN_REQUEST:"UPDATE_ASSIGN_REQUEST",
  UPDATE_ASSIGN_SUCCESS:"UPDATE_ASSIGN_SUCCESS",
  UPDATE_ASSIGN_FAILED: "UPDATE_ASSIGN_FAILED",
  GET_CUSDETAIL_REQUEST: "GET_CUSDETAIL_REQUEST",
  GET_CUSDETAIL_SUCCESS: "GET_CUSDETAIL_SUCCESS",
  GET_CUSDETAIL_FAILED: "GET_CUSDETAIL_FAILED",
  GET_ORDERSBYTARGET_REQUEST: "GET_ORDERSBYTARGET_REQUEST",
  GET_ORDERSBYTARGET_SUCCESS: "GET_ORDERSBYTARGET_SUCCESS",
  GET_ORDERSBYTARGET_FAILED: "GET_ORDERSBYTARGET_FAILED",

  GET_STAFFDETAIL_REQUEST: "GET_STAFFDETAIL_REQUEST",
  GET_STAFFDETAIL_SUCCESS: "GET_STAFFDETAIL_SUCCESS",
  GET_STAFFDETAIL_FAILED: "GET_STAFFDETAIL_FAILED",
  GET_STAFFDETAILTABLE_REQUEST: "GET_STAFFDETAILTABLE_REQUEST",
  GET_STAFFDETAILTABLE_SUCCESS: "GET_STAFFDETAILTABLE_SUCCESS",
  GET_STAFFDETAILTABLE_FAILED: "GET_STAFFDETAILTABLE_FAILED",
  USER_REGISTER_REQUEST: 'USER_REGISTER_REQUEST',
  USER_REGISTER_SUCCESS: 'USER_REGISTER_SUCCESS',
  USER_REGISTER_FAIL: 'USER_REGISTER_FAIL',
  USER_SIGNIN_REQUEST: 'USER_SIGNIN_REQUEST',
  USER_SIGNIN_SUCCESS: 'USER_SIGNIN_SUCCESS',
  USER_SIGNIN_FAIL: 'USER_SIGNIN_FAIL',
  USER_SIGNOUT_REQUEST: 'USER_SIGNOUT_REQUEST',
  USER_SIGNOUT: 'USER_SIGNOUT',

  EMPLOYEE_REGISTER_REQUEST: 'EMPLOYEE_REGISTER_REQUEST',
  EMPLOYEE_REGISTER_SUCCESS: 'EMPLOYEE_REGISTER_SUCCESS',
  EMPLOYEE_REGISTER_FAIL: 'EMPLOYEE_REGISTER_FAIL',
  EMPLOYEE_SIGNIN_REQUEST: 'EMPLOYEE_SIGNIN_REQUEST',
  EMPLOYEE_SIGNIN_SUCCESS: 'EMPLOYEE_SIGNIN_SUCCESS',
  EMPLOYEE_SIGNIN_FAIL: 'EMPLOYEE_SIGNIN_FAIL',
  EMPLOYEE_SIGNOUT_REQUEST: 'EMPLOYEE_SIGNOUT_REQUEST',
  EMPLOYEE_SIGNOUT: 'EMPLOYEE_SIGNOUT',

  GET_ALL_ORDERS_REQUESTED: 'GET_ALL_ORDERS_REQUESTED',
  GET_ALL_ORDERS_SUCCESS: 'GET_ALL_ORDERS_SUCCESS',
  GET_ALL_ORDERS_FAILED: 'GET_ALL_ORDERS_FAILED',


  USER_EMAIL_REQUEST: 'USER_EMAIL_REQUEST',
  USER_EMAIL_REQUEST_SUCCESS: 'USER_EMAIL_REQUEST_SUCCESS',
  USER_EMAIL_REQUEST_FAILED: 'USER_EMAIL_REQUEST_FAILED',

  EMPLOYEE_EMAIL_REQUEST: 'EMPLOYEE_EMAIL_REQUEST',
  EMPLOYEE_EMAIL_REQUEST_SUCCESS: 'EMPLOYEE_EMAIL_REQUEST_SUCCESS',
  EMPLOYEE_EMAIL_REQUEST_FAILED: 'EMPLOYEE_EMAIL_REQUEST_FAILED',

  USER_RESET_REQUEST: 'USER_RESET_REQUEST',
  USER_RESET_REQUEST_SUCCESS: 'USER_RESET_REQUEST_SUCCESS',
  USER_RESET_REQUEST_FAILED: 'USER_RESET_REQUEST_FAILED',

  EMPLOYEE_RESET_REQUEST: 'EMPLOYEE_RESET_REQUEST',
  EMPLOYEE_RESET_SUCCESS: 'EMPLOYEE_RESET_SUCCESS',
  EMPLOYEE_RESET_FAILED: 'EMPLOYEE_RESET_FAILED',

  DELETED_CUSTOMER_REQUEST: 'DELETED_CUSTOMER_REQUEST',
  DELETED_CUSTOMER_SUCCESS: 'DELETED_CUSTOMER_SUCCESS',
  DELETED_CUSTOMER_FAILED: 'DELETED_CUSTOMER_FAILED',
  DELETED_EMPLOYEE_REQUEST: 'DELETED_EMPLOYEE_REQUEST',
  DELETED_EMPLOYEE_SUCCESS: 'DELETED_EMPLOYEE_SUCCESS',
  DELETED_EMPLOYEE_FAILED: 'DELETED_EMPLOYEE_FAILED',

  CHANGE_ORDER: 'CHANGE_ORDER',

  GET_STATS_REQUEST: 'GET_STATS_REQUEST',
  GET_STATS_SUCCESS: 'GET_STATS_SUCCESS',
  GET_STATS_FAILED: 'GET_STATS_FAILED'
}
